@import '../../scss/variables.scss';
@import '../../scss/mixin.scss';

.header{
    margin-top: 5px;
    position: relative;
    z-index: 100;
    &__bottom-line{
        width: 100%;
        height: 2px;
        background-color: var(--color-green__to__color-orange);
    }
}

.heder-pc {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 4;
    background-color: var(--color-light-1__to__color-black-1);
    @media (max-width: 1260px){
        z-index: 2;
    }

    &__body{
        display: grid;
        grid-template-columns: 1fr 240px 1fr;

        margin: 0px auto 10px auto;

        position: relative;

        @media (max-width: 1260px){
            grid-template-columns: auto;
        }
    }

    &__line {
        padding: 3px 0 3px 0;

        & p {
            transition: transform .5s ease;
        }

        &:hover p{
            transform: translateY(-5px);
        }
    }

    // .heder-pc__left
    &__left {
        justify-self: end;
        align-self: self-end;

        display: flex;
        column-gap: 30px;

        padding: 0px 0px 7px 0px;

        @media (max-width: 1260px){
            display: none;
        }
    }

    // .heder-pc__link
    &__link {
        font-family: 'Mont';
        color: var(--color-bark-green__to__color-light-2);
        font-size: clamp(13px, .8vw, 17px);
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        white-space: nowrap;
    }

    // .heder-pc__logo
    &__logo {
        justify-self: center;
        align-self: self-end;

        & img{
            opacity: .85;
            object-fit: cover;
            height: 80px;
            width: auto;
            @media (max-width: 1200px){
                margin: 10px 0px 0px 0px;
                height: 80px;
            }
        }
    }

    // .heder-pc__right
    &__right {
        justify-self: start;
        align-self: self-end;

        display: flex;
        column-gap: 30px;

        padding: 0px 0px 7px 0px;
        @media (max-width: 1260px){
            display: none;
        }
    }
}

//= .burger 
.burger {
    background-color: #ffffff2b;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    transition: all 0.3s ease;
    transition-delay: 0.7s;
    position: fixed;
    top: 100px;
    left: 20px;
    z-index: 2;

    margin: -70px 0px 20px 0px;

    &.fixed {
        top: 85px;
    }
    &.active{
        opacity: 0;
        visibility: hidden;
        transition-delay: 0s;
    }
    @media (max-width: 1200px){
        visibility: visible;
    }

    //burger__body
    &__body{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    // .burger__line
    &__line {
        width: 80%;
        height: $hi-burger-line;
        background-color: var(--color-bark-green__to__color-orange);
        &::before {
            position: absolute;
            content: '';
            top: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 80%;
            height: $hi-burger-line;
            background-color: var(--color-bark-green__to__color-orange);
        }
        &::after {
            position: absolute;
            content: '';

            bottom: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 80%;
            height: $hi-burger-line;

            background-color: var(--color-bark-green__to__color-orange);
        }
    }
}

//= .menu-mob 
.menu-mob {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    height: 100%;

    padding: 0px 0px 0px 40px;

    background: var(--color-light-1__to__color-black-1);

    background-position: 70% 0;

    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1000;

    transition: all 0.4s ease;

    max-width: 500px;
    box-shadow: 0px 5px 15px 25px rgba(0, 0, 0, 0.3);

    border-radius: 0 30px 30px 0 / 0 40px 40px 0;
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-light-1__to__color-black-1);
    }
    &.active {
        left: 0;
    }
    
    &__close {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 40px;
        height: 40px;

        cursor: pointer;

        &::before{
            content: '';
            position: absolute;
            top: 15px;
            left: 0;
            width: 35px;
            height: 3px;
            transform: rotate(45deg);
            background-color: var(--color-bark-green__to__color-orange);
        } 
        &::after{
            content: '';
            position: absolute;
            top: 15px;
            left: 0;
            width: 35px;
            height: 3px;
            transform: rotate(-45deg);
            background-color: var(--color-bark-green__to__color-orange);
        }
    }

    // .menu-mob__line
    &__line {
        padding: 0px 0px 30px 0px;

        & p {
            transition: transform .5s ease;
        }

        &:hover p{
            transform: translateX(10px);
        }

    }

    // .menu-mob__link
    &__link {
        font-family: 'Mont';
        font-size: clamp(15px, .8vw, 17px);
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--color-bark-green__to__color-light-2);
    }
}

.activeLink {
    text-decoration: underline 2px var(--color-green__to__color-orange) !important;
    text-underline-offset: 4px;
}
