.showImage {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: var(--color-light-1__50__to__color-black-1__50);
    backdrop-filter: blur(9px);

    display: none;
    justify-content: center;
    align-items: center;

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;

        //background-color: red;

        &::after {
            content: '';
            position: absolute;
            top: 18px;
            left: 4px;

            height: 4px;
            width: 35px;
            background-color: var(--color-black-2__to__color-light-2);

            transform: rotate(-45deg);
        }
        &::before {
            content: '';
            position: absolute;
            top: 18px;
            left: 4px;

            height: 4px;
            width: 35px;
            background-color: var(--color-black-2__to__color-light-2);

            transform: rotate(45deg);
        }
    }

    &__body {
        max-width: 700px;
        width: 100%;
        max-height: 700px;
        height: 100%;
    }

    &__img {
        width: 100%;
        height: 100%;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
