.popupTheme {
    --padding: 12px;
    --radius: 20px;

    width: clamp(350px, 19vw, 450px);
    border-radius: var(--radius);

    position: fixed;
    z-index: 1000;
    bottom: 5%;
    right: 50%;

    transition: transform 1s ease;

    background-color: rgba(255, 255, 255, .5);
    backdrop-filter: blur(4px);

    // .popupTheme__body
    &__body {
    }

    // .popupTheme__up

    &__up {
        padding: var(--padding);
    }

    // .popupTheme__title

    &__title {
        font-size: 18px;
        font-family: 'Mont';
        font-weight: 600;
        text-align: center;
        letter-spacing: 1px;
    }

    // .popupTheme__box
    &__box {
        margin-top: var(--padding);

        display: flex;
        align-items: center;
    }

    // .popupTheme__img
    &__img {
        flex: 1 0 auto;
        width: clamp(50px, 3vw, 70px);
        aspect-ratio: 1/1;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    // .popupTheme__text
    &__text {
        padding-left: var(--padding);

        font-size: clamp(15px, .7vw, 17px);
        font-family: 'Mont';
        font-weight: 400;
        line-height: 120%;
        color: #000000a4;
    }



    // .popupTheme__down

    &__down {
        padding: var(--padding);

        border-top: 1px solid #1a1c1f20;

        display: flex;
        justify-content: space-between;
        gap: var(--padding);
    }

    // .popupTheme__light

    &__light {
        background-color: white;
        
        color: var(--color-black-2);
    }

    // .popupTheme__dark

    &__dark {
        background-color: var(--color-black-2);
        
        color: white;
    }

    &__button {
        width: 100%;
        aspect-ratio: 12/3;

        font-size: clamp(16px, .7vw, 17px); 
        font-weight: 500;
        font-family: 'Mont';
        letter-spacing: 1px;

        border-radius: calc(var(--radius) - var(--padding));
    }
}
